.log-table-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #757575;
    font-size: 14px;
}
.log-table-column:last-child {
    align-items: flex-start;
    padding-left: 5px;
}
.log-table-column-date {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.log-table-column-hour {
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.log-table-column-type {
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: left;
}
.log-table-column-header {
    font-weight: 600;
    font-size: 16px;
    color: #757575;
    margin-bottom: 20px;
}
.log-table-column:first-child {
    border-right: solid 2px #D2B37C;
}
.log-table-column:nth-child(2) {
    border-right: solid 2px #D2B37C;
}

.log-table-column-item {
    height: 19px;
    margin-bottom: 2px;
}
.log-table-column:nth-child(3) .log-table-column-item {
    margin-left: 10px;
}
.log-table-column:last-child {
    display: none;
}
@media only screen and (min-width: 500px) {
    .log-table-column:first-child {}
    .log-table-column:nth-child(2) {}
    .log-table-column:nth-child(3) {}
}

@media only screen and (min-width: 1200px) {
    .log-table-column-type {
        width: 150px;
    }
    .log-table-column:nth-child(3) {
        border-right: solid 2px #D2B37C;
    }
    .log-table-column:nth-child(3) .log-table-column-header {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-left: 10px;
        width: 150px
    }
    .log-table-column-text {
        width: auto;
        max-width: 670px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .log-table-column:last-child {
        display: flex;
        min-width: 0;
    }
}



/*@media only screen and (min-width: 1200px) {*/
/*    .log-table-column:nth-child(3) {*/
/*        border-right: solid 2px #D2B37C;*/
/*    }*/
/*}*/