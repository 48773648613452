.error-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.error-table-headers {
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
}

.error-table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px 0 5px;
    width: 60px;
    height: 25px;
    text-align: center;
    background-color: #D3B37D;
    border-right: solid 2px white;
}

.error-table-headers :first-child {
    background-color: #7A6855;
    border-radius: 4px 0 0 0;
    width: 210px;
    justify-content: left;
    padding-left: 10px;
}

.error-table-headers :last-child {
    background-color: #6F1D3C;
    border-radius: 0 4px 0 0;
    border-right: none;
}

.error-table-items {
    display: flex;
    flex-direction: row;
}

.error-table-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 25px;
    line-height: 25px;
    background-color: #f5f5f5;
    border-right: solid 2px white;
}

.error-table-items :first-child {
    width: 210px;
    display: table-row;
    vertical-align: middle;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.error-table-items :last-child {
    border-right: none;
}

.error-table-items:last-child > .error-table-item:first-child {
    border-radius: 0 0 0 4px;
}

.error-table-items:last-child > .error-table-item:last-child {
    border-radius: 0 0 4px 0;
}
.error-table-item-bg-error {
    background-color: #e6d3da;
}
@media only screen and (min-width: 500px) {
    .error-table-header {
        width: 70px;
    }
    .error-table-headers :first-child {
        width: 500px;
    }
    .error-table-item {
        width: 70px;
    }
    .error-table-items :first-child {
        width: 500px;
    }
}
@media only screen and (min-width: 1200px) {
    .error-table-headers :first-child {
        width: 340px;
    }
    .error-table-items :first-child {
        width: 340px;
    }
}