.main-home {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 574px;
    width: 351px;
    border-radius: 20px;
    background-color: #FFFDFD;
    margin-bottom: 55px;
}
.home-container > img {
    margin-bottom: 100px;
    margin-top: 80px;
}
.home-container-text {
    width: 299px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: #5A5959;
}

@media only screen and (min-width: 1200px) {
    .home-container {
        width: 1000px;
    }
}