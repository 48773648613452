.help-menu {
    position: absolute;
    right: 10px;
    bottom: 45px;
    background-color: rgba(108, 122, 137, 0.8);
    /*background-color: rgba(245, 247, 251, 0.8);*/
    width: 170px;
    opacity: 100%;
    border-radius: 10px;
}
.issue-icon {
    padding: 0 0 10px 0;
}
.issue-icon-style {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #D3B37D;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.help-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
}
.help-menu-item:first-child {
    padding-top: 10px;
}
.help-menu-item:last-child {
    padding-bottom: 10px;
}
.help-menu-item-text {
    padding-right: 10px;
}
.circle {
    height: 14px;
    width: 14px;
    border-radius: 20px;
    background-color: #D3B37D;
}
.help-menu > a {
    text-decoration: none;
    color: white;
    padding-bottom: 10px;
}

@media only screen and (min-width: 1200px) {
    .help-menu {
        right: 40px;
        bottom: 40px;
    }
    .help-menu > a {
        padding-bottom: 5px;
    }
}