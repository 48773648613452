.main-content {
    background-color: #FFFDFD;
    width: 351px;
    border-radius: 18px;
    margin-top: 20px;
}
.main-content:last-child {
    margin-bottom: 10px;
}

.title-content {
    font-size: 1rem;
    font-weight: 600;
    color: #757575;
    margin-left: 16px;
    padding-top: 20px;
    margin-bottom: 0;
}
@media only screen and (min-width: 500px) {
    .main-content {
        min-width: 351px;
        width: auto;
        max-width: 1000px;
        margin: 20px 20px 0 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .main-content {
        min-width: 500px;
        width: auto;
        max-width: 1000px;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .main {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 30px 0 30px;
    }
    .main-content:last-child {
        margin-bottom: 20px;
    }
}