.ant-select-arrow > span {
  color: #fff;
}
#root {
  font-size: 16px;
}
.app {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.app-logo {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 31px;
}
.page-bg {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #F5F7FB;
  /*background-color: blue;*/
  width: 95vw;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.app-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #6F1D3C;
  margin-top: 3.428rem;
  height: 75px;
  margin-bottom: 0;
}
.select-bot {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.select-bot-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.select-bot-date:first-child > div  {
  margin-right: 10px;
}
.shadow {
  filter: drop-shadow(0px 4px 2px rgba(0,0,0,0.3)) drop-shadow(0px -1px rgba(0,0,0,0.15));
}
.groupe-button {
  margin-top: 21px;
  width: 351px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #FFFDFD;
  border-radius: 13px;
  padding: 4px;
}
.btn {
  height: 39px;
  border-radius: 13px;
  padding: 9px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  background-color: #FFFDFD;
  color: #6E1C3B;
}
.btn-primary {
  background-color: #6E1C3B;
  color: #FFFDFD;
}
.help-menu-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}
.content {
  padding: 21px 10px;
}
.content-row {
  display: flex;
  flex-direction: row;
}
.display-errors {
  display: none;
}

.section {
  display: flex;
  flex-direction: column;
}
.section-title {
  display: none;
}
.section-content {
  display: flex;
  flex-direction: column;
}
.home-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.home-footer-version-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-footer-version {
  font-size: 10px;
  white-space: nowrap;
}

@media only screen and (min-width: 1200px) {
  .section {
    width: 100%;
  }
  .section-title {
    display: block;
    color: #6F1D3C;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    margin-left: 10vw;
  }
  .section-content {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
  }
  .app-logo {
    width: 121px;
    height: 75px;
  }
  .groupe-button {
    display: none;
  }
  .main {
    display: flex;
    flex-direction: column-reverse;
  }
  .display-iterations {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #F5F7FB;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 18px;
    margin-top: 20px;
  }
  .display-errors {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #F5F7FB;
    padding: 20px;
    border-radius: 18px;
  }
  #errors-types {
    height: 300px;
  }
  #quartile-time {
    display: flex;
    flex-direction: column;
    height: 280px;
  }
  #quartile-time > .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #executed-iteration {
    /*width: 400px;*/
  }
  .page-bg {
    background-color: white;
  }
}