.quartile-table-row {
    display: flex;
    flex-direction: row;
}
.quartile-table-row-header {
    width: 110px;
    height: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D3B37D;
    border-right: white solid 2px;
}
.quartile-table-row:last-child .quartile-table-row-header {
    border-radius: 0 0 0 4px;
    background-color: #7A6855;
}
.quartile-table-row:nth-child(2) .quartile-table-row-header {
    border-radius: 4px 0 0 0;
    background-color: #6F1D3C;
}
.quartile-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 25px;
    font-size: 14px;
    background-color: #F5F5F5;
    border-right: white solid 2px;
    border-bottom: white solid 1px;
}
.group-quartile-table-col-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 110px;
    margin-right: 1px;
}
.quartile-table-col-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 25px;
    font-size: 14px;
    font-weight: 600;
    background-color: #e5e5e5;
    border-right: solid 2px white;
    border-bottom: white solid 2px;
}
.quartile-table-col-header:first-child {
    border-radius: 4px 0 0 0;
}
.quartile-table-col-header:last-child {
    border-radius: 0 4px 0 0;
}
.quartile-table-row:last-child .quartile-value:last-child {
    border-radius: 0 0 4px 0;
}
.quartile-table-row:nth-child(2) .quartile-value {
    background-color: rgb(230, 211, 218);
}
@media only screen and (min-width: 500px) {
    .quartile-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .quartile-table-row-header {
        width: 150px;
    }
    .group-quartile-table-col-header {
        margin-left: 150px;
    }
}
