.iteration-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.iteration-table-headers {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 335px;
}

.iteration-table-header {
    width: 53px;
    height: 24px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D3B37D;
    border-right: solid 2px white;
}

.iteration-table-header:first-child {
    border-radius: 4px 0 0 0;
    background-color: #7A6855;
}

.iteration-table-header:last-child {
    border-radius: 0 4px 0 0;
    background-color: #6F1D3C;
    border-right: none;
}

.iteration-table-rows {
    display: flex;
    flex-direction: row;
    width: 335px;
}

.group-iteration-table-row {
    display: flex;
    flex-direction: column;
}

.iteration-table-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    padding: 10px 8px;
    font-size: 14px;
    background-color: #F5F5F5;
    border-right: solid 2px white;
    height: 48px;
    border-bottom: solid 1px lightgray;
}

.group-iteration-table-row:last-child .iteration-table-row {
    background-color: #e6d3da;
    border-bottom: solid 1px darkgray;
    border-right: solid 2px #e6d3da;
}

.iteration-row-header {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    font-size: 14px;
    padding: 10px 8px;
    height: 48px;
    border-right: solid 2px white;
    border-bottom: solid 1px lightgray;
}

.iteration-row-header:first-child {
    border-radius: 6px 0 0 0;
}

.iteration-row-header:last-child {
    border-radius: 0 0 0 6px;
    border-bottom: none;
}

.group-iteration-table-row .iteration-table-row:last-child {
    border-bottom: none;
}

.group-iteration-table-row:last-child .iteration-table-row:last-child {
    border-radius: 0 0 6px 0;
}
@media only screen and (min-width: 500px) {

}